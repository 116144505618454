<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a
            href="javascript:;"
            @click="init"
            class="cur-a"
          >{{projectName ? projectName : "学员学习记录"}}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          style="display: flex;flex-direction: column;align-items: flex-start; "
        >
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="userName" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="idCard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
            <div title="所属企业" class="searchboxItem ci-full">
              <span class="itemLabel">所属企业:</span>
              <el-select
                size="small"
                v-model="userCompId"
                remote
                :remote-method="getuserCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width:100%"
              >
                <el-option
                  v-for="item in userCompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="课程名称" class="searchboxItem ci-full" >
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="CourseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
          </div>
          <div class="searchbox" style="padding-top:10px">
           
            <div title="学习进度" class="searchboxItem ci-full">
              <span class="itemLabel">学习进度:</span>
              <el-select v-model="studyProgress" clearable placeholder="请选择" size="small">
                <el-option
                  v-for="item in LearningstateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="getModel()">导出</el-button>
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="getScreenCapture()"
              >截屏备案</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="120"
                fixed
              />
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="center"
                show-overflow-tooltip
                prop="idcard"
                width="150"
              />
              <el-table-column
                label="班级名称"
                align="center"
                show-overflow-tooltip
                prop="projectName"
                width="250"
              />
              <el-table-column
                label="机构名称"
                align="center"
                show-overflow-tooltip
                prop="compName"
                width="200"
              />
              <el-table-column
                label="课程名称"
                align="center"
                show-overflow-tooltip
                prop="courseName"
                width="260"
              />
              <el-table-column
                label="应修学时"
                align="center"
                show-overflow-tooltip
                prop="requireLessonNumMust"
              />
              <el-table-column
                label="已修学时"
                align="center"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column label="累计在线学习时长" align="center" show-overflow-tooltip width="140px">
                <template slot-scope="scope">
                  <span>{{ getTime(scope.row.totalTimeSum ) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="最后一次学习时间" align="center" show-overflow-tooltip width="140px">
                <template slot-scope="scope">
                  <span>{{ scope.row.endLastTime | moment }}</span>
                </template>
              </el-table-column>
              <el-table-column label="学习进度" align="center" show-overflow-tooltip>
                <span
                  slot-scope="scope"
                >{{$setDictionary("LEARNINGSTATE", scope.row.studyProgress)}}</span>
              </el-table-column>
              <el-table-column
                label="所属企业"
                align="center"
                show-overflow-tooltip
                prop="userCompName"
                width="200"
              />
              <el-table-column label="操作" align="center" width="300px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    @click="lookStudyRecord(scope.row.userId, scope.row.projectId)"
                  >查看学习记录</el-button>
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    @click="lookupTrainCert(scope.row.projectId, scope.row.userId) "
                    :disabled="scope.row.studyProgress!=30"
                  >查看学时证明</el-button>
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    @click="lookupTrainDocument(scope.row.projectId, scope.row.userId)"
                    :disabled="scope.row.studyProgress!=30"
                  >查看学员档案</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="查看学员档案"
      :visible.sync="dialogDownload"
      width="50%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 800px"></div>
    </el-dialog>
    <el-dialog
      title="查看学时证明"
      :visible.sync="dialogCert"
      width="50%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 800px"></div>
    </el-dialog>
    <el-dialog
      title="导出提示"
      :visible.sync="modelState"
      width="400px"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div class="model">
        <div class="conTip">导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。</div>
        <div title="请选择导出范围" class="searchboxItem">
          <span class="itemLabel">请选择导出范围:</span>
          <el-select
            size="small"
            v-model="classId"
            :remote-method="getClassListModel"
            remote
            filterable
            clearable
            placeholder="请输入班级名称"
          >
            <el-option
              v-for="item in classListModel"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="截屏备案"
      :visible.sync="ScreenCapturedialog"
      width="50%"
      top="2%"
      center
      :before-close="closeScreenCapture"
    >
      <div id="pdf-Screen" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "StudentLearningScreen",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      modelState: false, //弹框
      ScreenCapturedialog: false, //弹框
      ScreenCapture: false, //弹框
      classId: "",
      classListModel: [],
      projectList: [],
      userName: "",
      idCard: "",
      CourseName: "",
      areaId: "",
      projectId: "",
      userCompId: "",
      userCompanyList: [],
      LearningstateList: [], // 单位类型
      studyProgress: "",
      dialogDownload: false,
      dialogCert: false,
      areatreeList: [], // 行政区划
      selectDate: {
        areaId: "",
        compName: "",
        compType: "",
        endDateLong: "",
        startDateLong: ""
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      }
    };
  },
  created() {},
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (oldVal.path != "/web/StudentLearningRecordall") {
          this.areaId = "";
          this.screen = "";
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    },

    classId: function(val) {
      if (val == "") {
        this.classListModel = [];
      }
    }
  },
  created() {
    this.areaId = this.$route.query.areaId;
    this.screen = this.$route.query.stu;
    this.projectName = this.$route.query.projectName;
    this.projectId = this.$route.query.projectId || "";
    this.getTableHeight();
    this.getLearningstateList();
    this.getareatree();
    this.getClassListId(this.projectId);
  },
  methods: {
    getModel() {
      this.modelState = true;
    },
    closeDocsDialog() {
      this.modelState = false;
    },
    getScreenCapture() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idCard) {
        params.idcard = this.idCard;
      }
      if (this.CourseName) {
        params.courseName = this.CourseName;
      }
      if (this.studyProgress) {
        params.studyProgress = this.studyProgress;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.$route.query.projectId) {
        params.projectId = this.$route.query.projectId;
      }
      if (this.areaId || this.$route.query.areaId) {
        params.areaId = this.areaId || this.$route.query.areaId;
      }
      if (this.selectDate.compName) {
        params.compName = this.selectDate.compName;
      }
      if (this.selectDate.compType) {
        params.compType = this.selectDate.compType;
      }
      if (this.entrytime) {
        params.startDateLong = this.entrytime[0];
        params.endDateLong = this.entrytime[1];
      }
      this.$post("/gov/project/userStudyRecordExport", params).then(res => {
        if (res.status == 0) {
          this.ScreenCapturedialog = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-Screen");
          });
        } else {
          this.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    confirmDocsDialog() {
      if (this.classId) {
        this.$post("/gov/project/page-export", { projectId: this.classId })
          .then(res => {
            if (res.status == 0) {
              window.open(res.data);
              this.modelState = false;
              this.classId = "";
            }
          })
          .catch(() => {
            return;
          });
      } else {
        Message.error({
          message: "请输入班级名称",
          duration: "1000"
        });
      }
    },
    // 班级名称搜索列表
    getClassListModel(query) {
      if (query.trim()) {
        this.$post("/biz/project/queryProjectNameList", { projectName: query })
          .then(res => {
            if (res.status == 0) {
              this.classListModel = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.classListModel = [];
      }
    },
    //班级列表通过ID
    getClassListId(query) {
      if (query.trim()) {
        this.$post("/biz/project/queryProjectNameList", { projectId: query })
          .then(res => {
            if (res.status == 0) {
              this.projectList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.projectList = [];
      }
    },
    getClassList(query) {
      if (query.trim()) {
        this.$post("/biz/project/queryProjectNameList", { projectName: query })
          .then(res => {
            if (res.status == 0) {
              this.projectList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.projectList = [];
      }
    },
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.userCompId) {
        params.userCompId = this.userCompId;
      }
      if (this.idCard) {
        params.idcard = this.idCard;
      }
      if (this.CourseName) {
        params.courseName = this.CourseName;
      }
      if (this.studyProgress) {
        params.studyProgress = this.studyProgress;
      }

      if (this.projectId || this.$route.query.projectId) {
        params.projectId = this.projectId || this.$route.query.projectId;
      }
      if (this.areaId || this.$route.query.areaId) {
        params.areaId = this.areaId || this.$route.query.areaId;
      }
      if (this.selectDate.compName) {
        params.compName = this.selectDate.compName;
      }
      if (this.selectDate.compType) {
        params.compType = this.selectDate.compType;
      }
      if (this.entrytime) {
        params.startDateLong = this.entrytime[0];
        params.endDateLong = this.entrytime[1];
      }
      this.doFetch({
        url: "/gov/project/queryUserStudyRecordList",
        params,
        pageNum
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getLearningstateList() {
      const LearningstateList = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in LearningstateList) {
        list.push({
          value: key,
          label: LearningstateList[key]
        });
      }
      this.LearningstateList = list;
    },
    /* 查看学习记录 */
    lookStudyRecord(userId, projectId) {
      this.$router.push({
        path: "/web/ViewLearningRecords",
        query: {
          userId,
          projectId,
          needHold2: true
        }
      });
    },
    lookupTrainDocument(projectId, userId) {
      let parmar = {
        projectId: projectId,
        userId: userId
      };
      this.$post("/cert/downloadTrainDocument", parmar).then(res => {
        if (res.status == 0) {
          this.dialogDownload = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-content");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    closeDownloadTrainDocument() {
      this.dialogDownload = false;
    },
    lookupTrainCert(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId
      };
      this.$post("/cert/downloadCert", parma).then(res => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less" scoped>
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.model {
  .conTip {
    text-align: justify;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
</style>
